<template>
    <div class="continer">
        <div class="topMenu">
            <img src="../../assets/common/pic/logoRW.png" alt="" @click="backHome">
            <span class="txt">文档下载</span>
        </div>
        <div class="select">
            <div class="directory">
                <div class="directoryLi">工业级千兆以太交换机</div>
                <div class="directoryLi">无线车载单元</div>
                <div class="directoryLi">无线接入点控制器</div>
                <div class="directoryLi">无线快速切换基站</div>
                <div class="directoryLi">无线切换智能单元</div>
                <div class="directoryLi">吸顶式无线接入点</div>
                <div class="directoryLi">室内吸顶式无线接入点</div>
                <div class="directoryLi">智能云网关</div>
            </div>
            <div class="document">
                
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
  name: 'Document',
  methods: {
    backHome() {
      this.$router.replace('/')
    }
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>
  
<style scoped>
    .continer {
        width: 1920px;
        height: 100vh;
    }
    .topMenu {
        position: relative;
        padding-left: 260px;
        margin-bottom: 40px;
        width: 1920px;
        height: 70px;
        background-color: #c3a35d;
        box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
    }
    .topMenu img {
        margin-top: 8px;
        width: 154px;
    }
    .topMenu img:hover {
        cursor: pointer;
    }
    .topMenu .txt {
        position: absolute;
        top: 20px;
        font-size: 18px;
        color: #fff;
        margin-left: 20px;
    }
    .select {
        position: relative;
        margin-left: 420px;
        width: 400px;
        height: 600px;
    }
    .select .directory {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
    }
    .select .directoryLi {
        width: 300px;
        height: 50px;
        font-size: 16px;
        line-height: 50px;
        padding-left: 20px;
        background-color: #fff;
        border-bottom: 1px solid #ddd;
    }
    .select .document {
        position: absolute;
        top: 0;
        left: 420px;
        display: inline-block;
        width: 600px;
        height: 600px;
        background-color: #fff;
        box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
    }
</style>
  